import React from 'react';

import { BuildingStoreIcon } from '@zola/zola-ui/src/components/SvgIconsV3/BuildingStore';
import { DeviceDesktopIcon } from '@zola/zola-ui/src/components/SvgIconsV3/DeviceDesktop';
import { GiftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Gift';
import { HighHeelsIcon } from '@zola/zola-ui/src/components/SvgIconsV3/HighHeels';
import { ListGuestIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ListGuest';
import { DeviceMobileIcon } from '@zola/zola-ui/src/components/SvgIconsV3/DeviceMobile';
import { PaperInvitationsIcon } from '@zola/zola-ui/src/components/SvgIconsV3/PaperInvitations';
import { PaperPlaceCardIcon } from '@zola/zola-ui/src/components/SvgIconsV3/PaperPlaceCard';
import { PhotoAlbumIcon } from '@zola/zola-ui/src/components/SvgIconsV3/PhotoAlbum';
import { ReportMoneyIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ReportMoney';
import { VenueIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Venue';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';

import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import {
  BANDS_DJS_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import type { EventConfig } from '@zola/tracking-contracts/src/configs';
import { LocationOrAllMarketsType } from '@zola-helpers/client/dist/es/marketplace/types';
import { vendorSearchUrl } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import featureFlags from 'util/featureFlags';

// TODO: Add these to zola-helpers
export enum SupplementalBusinessUnitComponent {
  ADVICE = 'ADVICE',
  BOUTIQUE = 'BOUTIQUE',
  CORE = 'CORE',
  HONEYMOONS = 'HONEYMOONS',
  YOUR_WEDDING = 'YOUR WEDDING',
}

export type SecondaryNavItemType = {
  label: string;
  path: string;
  arrow?: boolean;
  isDisabled?: boolean;
  businessCategory?: EventConfig['business_category'];
  businessUnit?: EventConfig['business_unit'];
  target?: string;
  rel?: string;
};

export type SecondaryNavSection = {
  title?: string;
  items: Array<SecondaryNavItemType>;
};

export type SecondaryNavType = Array<SecondaryNavSection>;

export type PrimaryNavItemType = {
  id?: PrimaryNavIdentifier;
  label: string;
  path: string;
  businessCategory?: EventConfig['business_category'];
  businessUnit?: EventConfig['business_unit'];
  subnav?: SecondaryNavType;
  icon?: React.ReactNode;
};

export type PrimaryNavType = {
  title: string;
  position: number;
  nav: Array<PrimaryNavItemType>;
};

export const getSecondaryMarketplace = (location?: LocationOrAllMarketsType): SecondaryNavType => {
  return [
    {
      items: [
        {
          label: 'Take the vendor quiz',
          path: '/inspiration/onboard',
          arrow: true,
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Explore by location',
          path: '/wedding-vendors',
          businessUnit: 'MARKETPLACE',
        },
      ],
    },
    {
      title: 'Vendors',
      items: [
        {
          label: 'Venues',
          path: location
            ? vendorSearchUrl(VENUES_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-venues',
          businessCategory: 'VENUE',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Photographer',
          path: location
            ? vendorSearchUrl(PHOTOGRAPHERS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-photographers',
          businessCategory: 'PHOTOGRAPHER',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Caterers',
          path: location
            ? vendorSearchUrl(CATERING_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-catering',
          businessCategory: 'CATERER',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Florists',
          path: location
            ? vendorSearchUrl(FLORISTS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-florists',
          businessCategory: 'FLORIST',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Beauty Professionals',
          path: location
            ? vendorSearchUrl(HAIR_MAKEUP_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-hair-makeup',
          businessCategory: 'HAIR_MAKEUP',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Planners',
          path: location
            ? vendorSearchUrl(PLANNERS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-planners',
          businessCategory: 'PLANNER',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Bands and DJs',
          path: location
            ? vendorSearchUrl(BANDS_DJS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-bands-djs',
          businessCategory: 'MUSICIAN_DJ',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Cake and dessert',
          path: location
            ? vendorSearchUrl(CAKES_DESSERTS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-cakes-desserts',
          businessCategory: 'CAKES_DESSERTS',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Bar and beverages',
          path: location
            ? vendorSearchUrl(BANDS_DJS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-bar-services',
          businessCategory: 'BAR_SERVICES',
          businessUnit: 'MARKETPLACE',
        },
        {
          label: 'Videographers',
          path: location
            ? vendorSearchUrl(VIDEOGRAPHERS_TAXONOMY_KEY, location.slug)
            : '/wedding-vendors/wedding-videographers',
          businessCategory: 'VIDEOGRAPHER',
          businessUnit: 'MARKETPLACE',
        },
      ],
    },
    {
      items: [
        {
          label: 'Become a Zola vendor',
          path: '/for-vendors',
          businessUnit: 'VENDOR_EXPERIENCE',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      ],
    },
  ];
};

export const secondaryGuestList: SecondaryNavType = [
  {
    items: [
      {
        label: 'Build your guest list',
        path: '/wedding/onboard/wedding-guest-list',
        arrow: true,
        businessUnit: 'GUESTLIST',
      },
      {
        label: 'About guest list',
        path: '/wedding-planning/guests',
        businessUnit: 'GUESTLIST',
      },
    ],
  },
  {
    items: [
      {
        label: 'Contact Collector',
        path: '/wedding/onboard/wedding-guest-list',
        businessUnit: 'GUESTLIST',
      },
      {
        label: 'Book hotel blocks',
        path: 'https://hotelblocks.zola.com/?kw=guestnav',
        businessUnit: 'GUESTLIST',
      },
      {
        label: "RSVP to a couple's wedding",
        path: '/search/wedding-registry',
        businessUnit: 'GUESTLIST',
      },
    ],
  },
  {
    items: [
      {
        label: 'Learn more',
        path: '/wedding-planning/guests',
        businessUnit: 'GUESTLIST',
      },
    ],
  },
];

export const secondaryWebsite: SecondaryNavType = [
  {
    items: [
      {
        label: 'Start your wedding website',
        path: '/wedding/onboard/wedding-website',
        arrow: true,
        businessUnit: 'WEBSITE',
      },
      {
        label: 'About wedding websites',
        path: '/wedding-planning/website',
        businessUnit: 'WEBSITE',
      },
    ],
  },
  {
    items: [
      {
        label: 'Explore designs',
        path: '/wedding-planning/website/designs',
        businessUnit: 'WEBSITE',
      },
      {
        label: "Find a couple's wedding website",
        path: '/search/wedding-registry',
        businessUnit: 'WEBSITE',
      },
    ],
  },
];

export const secondaryRegistry: SecondaryNavType = [
  {
    items: [
      {
        label: 'Build your registry',
        path: '/onboard/new',
        arrow: true,
        businessUnit: 'REGISTRY',
      },
      {
        label: 'About registry',
        path: '/wedding-registry',
        businessUnit: 'REGISTRY',
      },
    ],
  },
  {
    title: 'Shop by category',
    items: [
      {
        label: 'Kitchen',
        path: '/shop/category/kitchen',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Tabletop',
        path: '/shop/category/dining',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Bed and bath',
        path: '/shop/category/bedding-and-bath',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Furniture',
        path: '/shop/category/furniture',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Home',
        path: '/shop/category/home',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Weekend',
        path: '/shop/category/weekend',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Experiences',
        path: '/shop/category/experiences',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Cash funds',
        path: '/shop/honeymoon-cash-funds',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Add gifts from another store',
        path: '/wedding-registry/add-from-other-stores',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Shop all',
        path: '/shop/brands',
        businessUnit: 'REGISTRY',
      },
    ],
  },
  {
    items: [
      {
        label: 'Learn more',
        path: '/wedding-registry',
        businessUnit: 'REGISTRY',
      },
    ],
  },
];

export const secondaryInvites: SecondaryNavType = [
  {
    items: [
      {
        label: 'Get free samples',
        path: '/wedding-planning/save-the-date/shop?multi_sample_tutorial=1',
        arrow: true,
        businessUnit: 'PAPER',
      },
      {
        label: 'About invites and paper',
        path: '/wedding-planning/paper',
        businessUnit: 'PAPER',
      },
    ],
  },
  {
    title: 'Shop by category',
    items: [
      {
        label: 'Collections',
        path: '/wedding-planning/suites/shop',
        businessUnit: 'PAPER',
      },
      {
        label: 'Save the dates',
        path: '/wedding-planning/save-the-date/shop',
        businessCategory: 'SAVE_THE_DATE',
        businessUnit: 'PAPER',
      },
      {
        label: 'Invitations',
        path: '/wedding-planning/invitations/shop',
        businessCategory: 'INVITATIONS',
        businessUnit: 'PAPER',
      },
      {
        label: 'Place cards',
        path: '/wedding-planning/place-cards/shop',
        businessCategory: 'PLACE',
        businessUnit: 'PAPER',
      },
      {
        label: 'Table numbers',
        path: '/wedding-planning/table-numbers/shop',
        businessCategory: 'TABLE_NUMBER',
        businessUnit: 'PAPER',
      },
      {
        label: 'Programs',
        path: '/wedding-planning/programs/shop',
        businessCategory: 'PROGRAM',
        businessUnit: 'PAPER',
      },
      {
        label: 'Menus',
        path: '/wedding-planning/menus/shop',
        businessCategory: 'MENU',
        businessUnit: 'PAPER',
      },
      {
        label: 'Thank you notes',
        path: '/wedding-planning/thank-you-cards/shop',
        businessCategory: 'THANK_YOU',
        businessUnit: 'PAPER',
      },
      {
        label: 'Shower invitations',
        path: '/wedding-planning/bridal-shower/shop',
        businessCategory: 'WEDDING_SHOWER_INVITATION',
        businessUnit: 'PAPER',
      },
      {
        label: 'Holiday cards',
        path: '/wedding-planning/holiday-cards/shop',
        businessCategory: 'HOLIDAY',
        businessUnit: 'PAPER',
      },
      {
        label: 'Digital save the dates',
        path: '/wedding-planning/digital/save-the-date/shop',
        businessCategory: 'DIGITAL_SAVE_THE_DATE',
        businessUnit: 'PAPER',
      },
      {
        label: 'Albums',
        path: '/wedding-albums/shop',
        businessCategory: 'ALBUM',
        businessUnit: 'PAPER',
      },
      {
        label: 'Shop all',
        path: '/wedding-planning/paper',
        businessUnit: 'PAPER',
      },
    ],
  },
];

export const secondaryBoutique: SecondaryNavType = [
  {
    title: 'Shop by category',
    items: [
      {
        label: 'Bridal',
        path: '/shop/category/bridal-boutique',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Wedding party gifts',
        path: '/shop/category/gifts-for-the-wedding-party',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Decor',
        path: '/shop/category/party-supplies-decor',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Favors',
        path: '/shop/category/wedding-gifts-favors',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Monogram shop',
        path: '/shop/collection/monogramming-shop',
        businessUnit: 'REGISTRY',
      },
      {
        label: 'Shop all',
        path: '/shop/category/wedding-shop',
        businessUnit: 'REGISTRY',
      },
    ],
  },
];

export const secondaryAdvice: SecondaryNavType = [
  {
    title: 'Advice categories',
    items: [
      {
        label: 'Getting engaged',
        path: '/expert-advice/topic/getting-engaged',
      },
      {
        label: 'Wedding planning 101',
        path: '/expert-advice/topic/wedding-planning-101',
      },
      {
        label: 'Wedding trends',
        path: '/expert-advice/topic/wedding-trends',
      },
      {
        label: 'Venues & vendors',
        path: '/expert-advice/topic/venues-vendors',
      },
      {
        label: 'Invites & paper',
        path: '/expert-advice/topic/invites-paper',
      },
      {
        label: 'Registry & gifts',
        path: '/expert-advice/topic/registry-gifts',
      },
      {
        label: 'Parties & events',
        path: '/expert-advice/topic/parties-events',
      },
      {
        label: 'Fashion & beauty',
        path: '/expert-advice/topic/fashion-beauty',
      },
      {
        label: 'Honeymoons',
        path: '/expert-advice/topic/honeymoons',
      },
      {
        label: 'Relationship advice',
        path: '/expert-advice/topic/relationship-advice',
      },
      {
        label: 'News & culture',
        path: '/expert-advice/topic/news-culture',
      },
      {
        label: 'Zola behind the scenes',
        path: '/expert-advice/topic/zola-behind-the-scenes',
      },
      {
        label: 'View all',
        path: '/expert-advice',
      },
    ],
  },
];

const ICON_SIZE = 20;

export const getPrimaryPlan = ({
  isMobile = false,
  location,
  seatingChartV2Released,
  shouldDisableSubnav = false,
}: {
  isMobile?: boolean;
  location?: LocationOrAllMarketsType;
  seatingChartV2Released: boolean;
  shouldDisableSubnav?: boolean;
}): PrimaryNavType => ({
  title: 'Plan your wedding',
  position: 1,
  nav: [
    {
      label: 'Wedding websites',
      path: '/wedding-planning/website',
      businessUnit: 'WEBSITE',
      subnav: shouldDisableSubnav ? undefined : secondaryWebsite,
      icon: <DeviceDesktopIcon title="Wedding websites" height={ICON_SIZE} width={ICON_SIZE} />,
    },
    {
      label: 'Wedding venues',
      path: vendorSearchUrl(VENUES_TAXONOMY_KEY, location?.slug || null),
      businessUnit: 'MARKETPLACE',
      icon: <VenueIcon title="Wedding venues" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Vendors',
      path: '/wedding-vendors',
      businessUnit: 'MARKETPLACE',
      subnav: shouldDisableSubnav ? undefined : getSecondaryMarketplace(location),
      icon: <BuildingStoreIcon title="Vendors" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Registry',
      path: '/wedding-registry',
      businessUnit: 'REGISTRY',
      subnav: shouldDisableSubnav ? undefined : secondaryRegistry,
      icon: <GiftIcon title="Registry" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Invites and paper',
      path: '/wedding-planning/paper',
      businessUnit: 'PAPER',
      subnav: shouldDisableSubnav ? undefined : secondaryInvites,
      icon: <PaperInvitationsIcon title="Invites and paper" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Guest list',
      path: '/wedding-planning/guests',
      businessUnit: 'GUESTLIST',
      subnav: shouldDisableSubnav ? undefined : secondaryGuestList,
      icon: <ListGuestIcon title="Guest list" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Budget',
      path: '/wedding-budget',
      businessUnit: 'BUDGET_TOOL',
      icon: <ReportMoneyIcon title="Budget" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      label: 'Boutique',
      path: isMobile ? '/shop/collection/wedding-shop-new-arrivals' : '/shop/category/wedding-shop',
      businessUnit: 'REGISTRY',
      subnav: shouldDisableSubnav ? undefined : secondaryBoutique,
      icon: <HighHeelsIcon title="Boutique" width={ICON_SIZE} height={ICON_SIZE} />,
    },
    ...insertInArrayIf(!seatingChartV2Released, {
      label: 'Albums',
      path: '/wedding-albums/shop',
      businessCategory: 'ALBUM' as EventConfig['business_category'],
      businessUnit: 'PAPER' as EventConfig['business_unit'],
      icon: <PhotoAlbumIcon title="Albums" width={ICON_SIZE} height={ICON_SIZE} />,
    }),
    ...insertInArrayIf(seatingChartV2Released, {
      label: 'Seating chart',
      path: '/wedding-planning/seating-chart',
      businessUnit: 'SEATING_CHART' as EventConfig['business_unit'],
      icon: <PaperPlaceCardIcon title="Seating chart" width={ICON_SIZE} height={ICON_SIZE} />,
    }),
    ...insertInArrayIf(typeof window !== 'undefined' && featureFlags.get('enableAppLP'), {
      label: 'Mobile app',
      path: '/wedding-planning/app',
      businessUnit: 'MOBILE_APP' as EventConfig['business_unit'],
      icon: <DeviceMobileIcon title="Seating chart" width={ICON_SIZE} height={ICON_SIZE} />,
    }),
  ],
});

export const primaryAdvice: PrimaryNavType = {
  title: 'Expert advice',
  position: 2,
  nav: [
    {
      label: 'Team-Z advisors',
      path: '/advisor',
    },
    {
      id: PrimaryNavIdentifier.ADVICE,
      label: 'Expert Advice',
      path: '/expert-advice',
      subnav: secondaryAdvice,
    },
    {
      label: 'Inspiration',
      path: '/inspiration',
      businessUnit: 'REAL_WEDDINGS',
    },
    {
      label: 'Honeymoons',
      path: '/wedding-planning/honeymoons',
      id: PrimaryNavIdentifier.HONEYMOONS,
      businessUnit: 'HONEYMOONS',
    },
    {
      label: 'FAQs',
      path: '/faq',
    },
    {
      label: 'Contact us',
      path: '/contact-us',
    },
  ],
};
