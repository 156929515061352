import React, { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { Global, css } from '@emotion/react';
import { useSelector } from 'react-redux';

import HeartIcon from '@zola/zola-ui/src/components/SvgIcons/Heart';
import { Menu2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Menu2';
import Notification from 'components/nav/TopNav/Notification';
import CartContainer from 'components/nav/TopNav/CartContainer';
import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  SecondaryNavItem,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import MobileNavMenu from 'components/navV3/mobile/MobileNavMenu';
import { MobileSecondaryNav } from 'components/navV3/PreAuthMobile/MobileSecondaryNav';
import UniversalSearch from 'components/search/UniversalSearch';

import { toggleDocumentOverlayClass } from 'util/overlayHelper';
import { isLoggedIn } from 'util/authHelpers';
import { getUserContext } from 'selectors/user';
import { getFavoritesLink } from 'util/getFavoritesLink';
import useScrollPosition from '../../../hooks/useScrollPosition';

import {
  Collapse,
  Nav,
  TopNavBar,
  TopNavBarLeft,
  StyledLinkV2,
  StyledZolaLogo,
  SharedIconStyles,
  SharedIconStylesWithBadge,
  UniversalSearchContainer,
} from './MobileNavV3.styles';
import '../../../stylesheets/nav/MobileNavContainer.less';

type MobileNavProps = {
  primaryLinkId: PrimaryNavIdentifier | '';
  secondaryLinkId: SecondaryNavIdentifier | '';
  secondaryNavData: SecondaryNavItem[];
  tertiaryLinkId: TertiaryNavIdentifier | '';
  tertiaryNavData: SecondaryNavItem[];
};

const MobileNav = ({
  primaryLinkId,
  secondaryLinkId,
  secondaryNavData,
  tertiaryLinkId,
  tertiaryNavData,
}: MobileNavProps): JSX.Element => {
  const userContext = useSelector(getUserContext);
  const { object_id: userObjectId } = userContext;

  // by instanstiating as undefined, it prevents the animation on initial render; all subsequent updates will be boolean
  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>(undefined);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const showSecondaryNav = useMemo(() => secondaryNavData.length > 0, [secondaryNavData.length]);
  const [collapseOnScroll, setCollapseOnScroll] = useState<boolean | undefined>(undefined);
  const { direction } = useScrollPosition();

  // Collapse nav until scrolled to top
  const shouldCollapseOnScroll =
    (typeof window !== 'undefined' && window.scrollY > 0 && direction === 'up') ||
    direction === 'down';

  useEffect(() => {
    if (shouldCollapseOnScroll !== collapseOnScroll) {
      setCollapseOnScroll(shouldCollapseOnScroll);
    }
  }, [collapseOnScroll, shouldCollapseOnScroll]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleDocumentOverlayClass(!isMenuOpen, 'mobile-nav-v3--active');
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <Nav>
      <TopNavBar>
        <TopNavBarLeft>
          <StyledLinkV2 as="button" onClick={toggleMenu}>
            <Menu2Icon height={24} title="" width={24} />
          </StyledLinkV2>
          <a href="/" target="_self" aria-label="Zola Homepage">
            <StyledZolaLogo color={COLORS3.BLACK_100} />
          </a>
        </TopNavBarLeft>
        {isLoggedIn(userContext) && (
          <Fragment>
            {/* https://emotion.sh/docs/typescript explains why we have to use Fragment */}
            <a
              css={SharedIconStyles}
              href={getFavoritesLink(primaryLinkId)}
              data-testid="mobile-nav-favorite-link"
            >
              <HeartIcon width={24} height={24} />
            </a>
            {userObjectId && (
              <Notification css={SharedIconStylesWithBadge} userId={userObjectId} isMobileNavV3 />
            )}
          </Fragment>
        )}
        <CartContainer css={SharedIconStylesWithBadge} isNavV2={false} />
      </TopNavBar>
      {showSecondaryNav ? (
        <Collapse collapseOnScroll={collapseOnScroll}>
          <MobileSecondaryNav
            primaryLinkId={primaryLinkId}
            secondaryLinkId={secondaryLinkId}
            secondaryNavData={secondaryNavData}
            tertiaryLinkId={tertiaryLinkId}
            tertiaryNavData={tertiaryNavData}
          />
        </Collapse>
      ) : null}
      <MobileNavMenu isMenuOpen={isMenuOpen} toggleSearch={toggleSearch} toggleMenu={toggleMenu} />
      {isSearchOpen && (
        <Suspense fallback={<div>loading search result</div>}>
          <UniversalSearchContainer>
            <UniversalSearch activeLinkId={primaryLinkId} onCloseSearch={toggleSearch} />
          </UniversalSearchContainer>
        </Suspense>
      )}
      <Global
        styles={css`
          .root-nav {
            z-index: 40;
          }
        `}
      />
    </Nav>
  );
};

export default MobileNav;
