import { UserContext } from '@zola-helpers/client/dist/es/@types';
import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';

// Experiment Keys:
// 👇 example key (currently deprecated)
// export const EXAMPLE_FLAG = 'example-flag;
// keep for cookie value?
export const RECAPTCHA_REFACTOR = 'recaptcha-refactor'; // https://newamsterdamlabs.atlassian.net/browse/CE-2186
export const HOLIDAY_CARDS = 'holiday-cards'; // Seasonal holiday cards
export const HOLIDAY_SHOPS = 'holiday-shops'; // Seasonal shop (non-card) features
export const PAPER_DGC_IN_CART_V5 = 'paper-dgc-in-cart-v5';
export const CLP_LP_LINKS_TO_SRP = 'marketplace-clp-lp-links-to-srp'; // PE-989
export const ENHANCED_PAPER_IN_CART = 'enhanced-paper-in-cart'; // Show card previews in cart

const FALLBACK_VARIATIONS = {
  // 👇 example variation (currently deprecated)
  // [EXAMPLE_FLAG]: experimentFlagsHelper.createExperimentFlags(),
  [RECAPTCHA_REFACTOR]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_CARDS]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_SHOPS]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_DGC_IN_CART_V5]: experimentFlagsHelper.createExperimentFlags(),
  [CLP_LP_LINKS_TO_SRP]: experimentFlagsHelper.createExperimentFlags(),
  [ENHANCED_PAPER_IN_CART]: experimentFlagsHelper.createExperimentFlags(),
};

const { getFlag, getExperimentVariation, isUnderTest, isInExperiment } =
  experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

const clientSafeGetFlag = (flag: keyof typeof FALLBACK_VARIATIONS, userContext?: UserContext) => {
  return typeof window !== 'undefined' && getFlag(flag, userContext);
};

const experimentFlags = {
  getExperimentVariation,
  isUnderTest,
  getFlag: clientSafeGetFlag,
  isInExperiment,
};

export default experimentFlags;
